<template>
  <div class="order-detail">
    <nav-bar :bgc="'background:#fff'" :isFixed="true"
      :title="`訂單${orderStatus(payOrderData.status,payOrderData)}`"></nav-bar>
    <div :class="['order-content',{ headerTop: !isWeiXin() }]" class="order-content">
      <div class="adderss">
        <div :class="['order-status',{ 'isCenter': payOrderData.checkStatus == 0 }]">
          <div class="pay-name">
            <img v-if="
              payOrderData.status == 0 &&
              (count > 0 ||
                payOrderData.expiredMinutes > 0 ||
                payOrderData.expiredHour > 0)
            " src="@/assets/image/user/general.png" alt="" />
            <img v-if="
              (payOrderData.status == 0 &&
                count == 0 &&
                payOrderData.expiredMinutes == 0 &&
                payOrderData.expiredHour == 0) ||
              payOrderData.status == 5
            " src="@/assets/image/user/orderclose.png" alt="" />
            <img v-if="payOrderData.status == 2" src="@/assets/image/user/order.png" alt="" />
            {{ $t(`訂單${orderStatus(payOrderData.status,payOrderData)}`) }}
          </div>
          <div class="checkStatus" v-if="payOrderData.checkStatus === 0 && payOrderData.payType === 53">{{ $t('未付支票') }}
          </div>
        </div>
        <div class="pay-time" :style="isEn === '3' ? 'display: flex;' : ''" v-if="
          count > 0 ||
          payOrderData.expiredMinutes > 0 ||
          payOrderData.expiredHour > 0
        ">
          {{ $t("订单将在") }}
          <div class="time">
            {{ sumZore(payOrderData.expiredHour) }}:{{
              sumZore(payOrderData.expiredMinutes)
            }}:{{ sumZore(count) }}
          </div>
          <div v-if="isEn === '3'">{{ $t("後自動關閉") }}</div>
        </div>
        <div v-if="payOrderData.status === 0">
          <div class="gotopay" @click="handleOpenPay">
            {{ $t("去支付") }}
          </div>
          <div class="Cancelpay" @click="handleCancelOrder">
            {{ $t("取消訂單") }}
          </div>
        </div>
      </div>
      <div class="pay-type">
        <div class="phone-list">
          <div class="consignee-list">
            <div class="consignee">{{ payOrderData.consignee }}</div>
            <div class="phone-consignee">{{ payOrderData.consigneePhone }}</div>
          </div>
          <div>
            <div class="consignee-address">{{ payOrderData.address }}</div>
          </div>
        </div>
      </div>
      <div class="Stash">
        <div class="goods-list" v-for="(item,index) in payOrderData.shardingPayOrder" :key="index">
          <div class="Stash-title">
            {{ item.storeName }}
          </div>
          <div class="goods-item" v-for="(goods,index) in item.goods.slice(0,orderIndex)" :key="index">
            <div class="imgbor">
              <img @error="imgOnError($event)" :src="getProductImageUrl(goods.picturePath,400)" alt="" />
            </div>
            <div class="goods-info">
              <div class="goods-name">
                {{ goods.productName }}
              </div>
              <div class="goods-unit">{{ goods.standard }}</div>
              <div class="goods-price">
                <div class="price">{{ formatNumber(goods.unitPrice) }}</div>
                <div class="count">{{ $t("數量") }} {{ goods.amount }}</div>
              </div>
            </div>
          </div>
          <div class="viewall" @click="getViewallGoods('1')" v-if="item.goods.length > 3 && unfold">
            {{ $t("查看所有") }}{{ item.goods.length }}{{ $t("個商品") }}
            <img src="@/assets/icons/direction@2x.png" alt="" />
          </div>
          <div class="viewall" @click="getViewallGoods('2')" v-if="!unfold">
            {{ $t("收起") }}
            <img src="@/assets/icons/unfold.png" alt="" />
          </div>
          <div>
            <div class="Distribution-mode">
              <div class="Distribution-item">
                <div class="label">{{ $t("配送方式") }}</div>
                <div>{{ item.expressName }}</div>
              </div>
              <div class="Distribution-item">
                <div class="label">{{ $t("運費") }}</div>
                <div class="freight">
                  {{ formatNumber(item.freight) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="leaveMessage">
        <div class="aggregate">
          {{ $t("訂單總計") }}({{ length }}{{ $t("件商品") }})
        </div>
        <div class="aggregate-list">
          <div class="aggregate-price">
            <div>{{ $t("產品價格") }}</div>
            <div>{{ formatNumber(goodsTotal) }}</div>
          </div>
          <div class="aggregate-price">
            <div>{{ $t("運費總計") }}</div>
            <div>
              {{ formatNumber(payOrderData.freight) }}
            </div>
          </div>
          <div class="aggregate-price">
            <div>{{ $t("優惠") }}</div>
            <div class="discountsprice">
              -{{ formatNumber(payOrderData.discountPrice) }}
            </div>
          </div>
          <div class="aggregate-price">
            <div>{{ $t("訂單總計") }}</div>
            <div class="discountsprice">
              {{ formatNumber(payOrderData.totalPrice) }}
            </div>
          </div>
        </div>
        <div class="order-no">
          <div class="aggregate-price">
            <div>{{ $t("訂單編號") }}</div>
            <div>{{ payOrderData.orderNo }}</div>
          </div>
          <div class="aggregate-price">
            <div>{{ $t("創建時間") }}</div>
            <div>{{ payOrderData.createTime }}</div>
          </div>
          <div class="aggregate-price" v-if="payOrderData.payTypeName">
            <div>{{ $t("支付方式") }}</div>
            <div>
              <div>{{ payOrderData.payTypeName }}</div>
              <div v-if="payOrderData.payType === 53" class="text-color" @click="handeleOrderprompt">{{ $t("支票付款方式") }}
              </div>
            </div>
          </div>
          <div class="aggregate-price" v-if="payOrderData.payDate">
            <div>{{ $t("支付時間") }}</div>
            <div>{{ payOrderData.payDate }}</div>
          </div>
        </div>
      </div>
      <div class="order-footer" v-if="payOrderData.status === 0">
        <div class="Placeorder" @click="handleOpenPay">
          {{ $t("去支付") }}
        </div>
      </div>
    </div>
    <!-- 自定义图标 -->
    <van-popup v-model:show="isPayshow" round :close-on-click-overlay="false" safe-area-inset-bottom position="bottom"
      :style="{ height: '66vh' }" @close="handlePayshowClose">
      <div class="checkstand">
        <div class="checkstand-title">
          {{ $t("收銀台") }}
          <div class="close">
            <img @click="handleClosePay" style="width: 100%" src="@/assets/icons/ic_Close@2x.png" alt="" />
          </div>
        </div>
        <div class="checkstand-price">
          {{ formatNumber(payOrderData.totalPrice) }}
        </div>
        <div class="select-pay">{{ $t("請選擇支付方式") }}</div>
        <div class="pay-list">
          <div class="phone-list">
            <div :class="['phone-item',{ actvie: item.value == selectPhone }]" v-for="item in payList" :key="item.value"
              @click="handleAreaCode(item)">
              <div class="pay-item">
                <div class="phone-item-icon">
                  <img class="pay-icon" :src="BASE_IMG + item.icon" alt="" />
                  <div class="phone-text">{{ item.name }}</div>
                </div>
                <div class="pay-price" v-if="item.value == selectPhone">
                  <span class="price-text">{{ $t("支付") }}</span>{{ formatNumber(payOrderData.totalPrice) }}
                </div>
              </div>
              <img class="phone-check" v-if="item.value == selectPhone" src="@/assets/icons/check.png" alt="" />
            </div>
          </div>
        </div>
        <div class="pay-footer">
          <div class="pay-btn" @click="handlePayOrder">
            {{ $t("立即支付") }}
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 提示弹窗 -->
    <van-dialog v-model:show="isCancelPay" :showConfirmButton="false">
      <div class="register-dialog">
        <div class="title">
          <div>{{ $t("提示") }}</div>
          <img @click="isCancelPay = false" src="@/assets/icons/ic_Close@2x.png" alt="" />
        </div>
        <div class="register-text">
          <div>{{ $t("確認取消支付？") }}</div>
        </div>
        <div class="pay-btn">
          <div class="Confirm" @click="isCancelPay = false">
            {{ isCancelOrder ? $t("再想想") : $t("繼續支付") }}
          </div>
          <div class="Cancel" @click="getCancelpay">
            {{ isCancelOrder ? $t("取消订单") : $t("取消支付") }}
          </div>
        </div>
      </div>
    </van-dialog>

    <!-- 银行卡弹窗 -->
    <van-popup v-model:show="isStripePayshow" round :close-on-click-overlay="false" position="bottom"
      safe-area-inset-bottom :style="{ height: '60%' }">
      <div class="stripe-container">
        <stripe :orderNo="payOrderData.orderNo" :price="payOrderData.totalPrice" @handleSubmit="handleSubmit"
          @handleClosestripe="handleClosestripe" v-if="isStripe" :clientSecretKey="clientSecretKey"></stripe>
      </div>
    </van-popup>
    <popup ref="popup" :isCancel="false" :popupShow="isPrompt" :popupTitle="popupTitle"
      :popupContent="orderInfo.chequeAddress" @cancelPopup="handeleCancelPopup" @confirmPopup="handeleConfirmPopup">
    </popup>
  </div>
</template>

<script>
import { getProductOrderDetail } from "@/api/productdetails.js"
import mixins from "@/utils/mixins.js"
import { mapState } from "vuex"
import popup from "@/components/popup/index"
import {
  getCreateOrder2,
  getPayOrder,
  getOrderDetails,
  getSubmitPayOrder2,
  pollOrderStatusByPay,
  getStripeKey,
  closeOrder,
  getConfig,
  getWxOauth,
  getOpenId
} from "@/api/user.js"
import Cookies from "js-cookie"
import baseURL from "@/utils/config"
import JSEncrypt from "jsencrypt"
export default {
  mixins: [mixins],
  components: {
    popup
  },
  data () {
    return {
      columns: [],
      orderData: {},
      selectPhone: "9",
      orderIndex: 3,
      freightTotal: 0,
      goodsTotal: 0,
      preferential: 0,
      length: 0,
      unfold: true,
      defaultAddresses: {},
      remark: "",
      payOrderData: "",
      count: "",
      isPayshow: false,
      payList: [],
      isCancelPay: false,
      payForm: {
        orderNo: "",
        payType: "1",
        bank: "",
        mixFlag: "0",
        preType: null,
        openId: ""
      },
      loadStripeKey: "",
      isExistenceRecursion: false, //轮询开关
      isStripe: false,
      clientSecretKey: "",
      isStripePayshow: false,
      isEn: null,
      isCancelOrder: false,
      alipay: "",
      wxPayData: {
        appId: "", //公众号名称，由商户传入
        timeStamp: "", //时间戳，自1970年以来的秒数
        nonceStr: "", //随机串
        package: "",
        signType: "MD5", //微信签名方式：
        paySign: "" //微信签名
      },
      isPrompt: false,
      popupTitle: this.$t('您有以下幾種方式進行支票匯款'),
      orderInfo: {
        chequeAddress: null,
      }
    }
  },
  computed: {
    ...mapState("user", ["checkAddress"])
  },
  async created () {
    window.localStorage.setItem("wxPayPath", this.$route.fullPath)
    // 判断有code
    if (Cookies.get("wxCode")) {
      // 获取openid
      const res = await getOpenId({
        code: Cookies.get("wxCode"),
        state: Cookies.get("wxState"),
        type: "1"
      })
      this.payForm.openId = res.data.openid
    }
    if (
      this.isWeiXin() &&
      !this.$route.query.flag &&
      !this.$route.query.cancel
    ) {
      const res1 = await getWxOauth()
      window.location.href = res1.data
    }

    const res = await getConfig({
      id: encodeURIComponent(`${window.location.href}`)
    })

    //wx支付返回查询状态
    if (this.$route.query.seachStatus) {
      this.pollOrderStatusByPay()
    }

    this.isEn = window.localStorage.getItem("langEnv")
    await this.getOrderDetail()
    await this.getCreateOrderList()
    this.getFreightTotal()

    this.payList = this.payList?.filter(item => item.value != "9")

    this.selectPhone = this.payList[0]?.value
    if (this.checkAddress?.id) {
      this.defaultAddresses = this.checkAddress
    } else {
      this.defaultAddresses = this.orderData.addresses.find(
        item => item.isDefault
      )
    }
    if (this.payOrderData.status == 0) {
      this.isPayshow = true
    }
    console.log(this.freightTotal)
    console.log(this.preferential)
  },
  methods: {
    onBridgeReady () {
      WeixinJSBridge.invoke("getBrandWCPayRequest", this.wxPayData, res => {
        console.log("回调")

        window.location.href =
          process.env.NODE_ENV == "production"
            ? `https://m.e-rith.com/pay/${this.$route.params.orderNo}?seachStatus=true&cancel=true`
            : `http://test-www3.hansfive.com/pay/${this.$route.params.orderNo}?seachStatus=true&cancel=true`
        // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
      })
    },
    // 如何操作
    handeleOrderprompt () {
      this.orderInfo.chequeAddress = this.orderData.paymentSet.chequeAddress
      this.isPrompt = true
    },
    handeleCancelPopup () {
      this.isPrompt = false
    },
    handeleConfirmPopup () {
      this.isPrompt = false
    },
    handlePayshowClose () {
      this.isCancelOrder = false
    },
    handleCancelOrder () {
      this.isCancelPay = true
      this.isCancelOrder = true
    },
    handleClosestripe () {
      this.isStripePayshow = false
      this.$toast(this.$t("支付被取消"))
    },
    handleSubmit (data) {
      if (data?.error) {
        this.$toast(data.error.message)
      }
      console.log(data)
    },
    async pollOrderStatusByPay () {
      const res = await pollOrderStatusByPay({
        id: this.$route.params.orderNo
      })
      if (!this.isExistenceRecursion) this.isExistenceRecursion = true
      if (res.resultID === 1200) {
        if (res.data && res.data.isPay) {
          // 跳转支付结果
          this.$router.push(
            "/payResult?type=success&pay=1&payType" +
            this.payForm.payType +
            "&orderId=" +
            this.$route.params.orderNo +
            "&price=" +
            this.payOrderData.totalPrice
          )
        } else {
          if (this.pollOrderStatusByPay) this.pollOrderStatusByPay()
        }
      }
    },
    async handlePayOrder () {
      const userAgent = navigator.userAgent.toLowerCase()
      const paytoast = this.$toast({
        message: this.$t("正在啟動支付，請稍後"),
        duration: 0,
        forbidClick: true
      })
      this.payForm.orderNo = this.payOrderData.orderNo
      this.payForm.payType = this.selectPhone
      if (this.selectPhone == "1") {
        if (this.isWeiXin()) {
          this.payForm.payType = "6"
        } else {
          this.payForm.payType = "55"
        }
      }
      if (!this.loadStripeKey) {
        const res = await getStripeKey()
        this.loadStripeKey = res.data
        console.log(this.loadStripeKey)
      }
      let appSuccessCallBack = window.location.origin + "/payResult?type=success&pay=1&payType=" +
        this.payForm.payType +
        "&orderId=" +
        this.$route.params.orderNo +
        "&price=" +
        this.payOrderData.totalPrice
      let appFailCallback = window.location.origin + "/payResult?type=fail" +
        "&orderId=" + this.$route.params.orderNo +
        '&pay=1' + "&price=" +
        this.payOrderData.totalPrice +

        "&message=訂單失效"
      console.log(appFailCallback)
      const res = await getSubmitPayOrder2({ ...this.payForm, appFailCallback, appSuccessCallBack })
      if (res.resultID === 1200) {
        paytoast.close()
        //银行卡
        if (this.selectPhone == "52") {
          this.clientSecretKey = res.data.client_secret
          this.isStripe = true
          this.isStripePayshow = true
        }
        // wx支付
        if (this.payForm.payType == "55" || this.payForm.payType == "6") {
          if (this.isWeiXin()) {
            this.wxPayData.package = res.data.packageStr
            this.wxPayData.appId = res.data.appid
            this.wxPayData.timeStamp = res.data.timestamp
            this.wxPayData.nonceStr = res.data.noncestr
            this.wxPayData.paySign = res.data.sign
            this.wxPayData.signType = res.data.signType

            if (typeof WeixinJSBridge == "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  onBridgeReady,
                  false
                )
              } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", onBridgeReady)
                document.attachEvent("onWeixinJSBridgeReady", onBridgeReady)
              }
            } else {
              console.log(this.wxPayData)

              this.onBridgeReady()
            }
          } else {
            //微信支付
            window.location.href =
              res.data.mweb_url +
              `&redirect_url=${process.env.NODE_ENV == "production"
                ? `https://m.e-rith.com/pay/${this.$route.params.orderNo}?seachStatus=true`
                : `http://test-www3.hansfive.com/pay/${this.$route.params.orderNo}?seachStatus=true`
              }`
          }
        } else if (this.payForm.payType == "50") {
          //支付宝海外版
          window.location.href = res.data?.normalUrl
          // console.log(res);
        } else if (this.payForm.payType == "51") {
          // paypal
          window.location.href = res.data.links.find(
            item => item.rel === "approve"
          ).href
        } else if (this.payForm.payType == "54") {
          //支付宝HK
          // this.alipay=res.data.qrCodeHtml;
        } else if (this.payForm.payType == "56") {
          window.location.href = res.data.webLink
        }
      }
      console.log(this.wxPayData)

      if (!this.isExistenceRecursion) {
        this.pollOrderStatusByPay()
      }
    },
    async getCancelpay () {
      if (this.isCancelOrder) {
        const res = await closeOrder({ id: this.$route.params.orderNo })
        if (res.resultID === 1200) {
          location.reload()
        }
      }
      this.isCancelPay = false
      this.isPayshow = false
    },
    handleClosePay () {
      this.isCancelPay = true
    },
    handleOpenPay () {
      this.isPayshow = true
    },
    sumZore (sum) {
      if (sum < 10) {
        return "0" + sum
      } else {
        return sum
      }
    },
    daysToHours (days) {
      return days * 24
    },
    async getCreateOrderList () {
      const res = await getOrderDetails({
        orderNo: this.$route.params.orderNo
      })
      this.payOrderData = res.data
      if (this.payOrderData.expiredDay > 0 && this.payOrderData.expiredHour >= 0) {
        this.payOrderData.expiredHour = this.daysToHours(this.payOrderData.expiredDay) + this.payOrderData.expiredHour
      }
      if (res.data.status === 0) {
        const list = await getPayOrder({ id: this.$route.params.orderNo })

        if (list.resultID == 1200) {
          this.payList = list.data.paymentTypes
          this.count = res.data.expiredSeconds
          this.timer = setInterval(async () => {
            if (this.count > 0) {
              this.count -= 1
            } else {
              if (this.payOrderData.expiredMinutes > 0) {
                this.payOrderData.expiredMinutes =
                  this.payOrderData.expiredMinutes - 1
                this.count = 59
              } else {
                if (this.payOrderData.expiredHour > 0) {
                  this.payOrderData.expiredHour =
                    this.payOrderData.expiredHour - 1
                  this.payOrderData.expiredMinutes = 59
                } else {
                  await closeOrder({ id: this.$route.params.orderNo })
                  this.$router.push(
                    "/payResult?type=fail" +
                    "&orderId=" +
                    this.$route.params.orderNo +
                    "&message=訂單失效" + '&pay=1' + "&price=" +
                    this.payOrderData.totalPrice
                  )
                  clearInterval(this.timer)
                }
              }
            }
          }, 1000)
        }
      } else {
        if (this.payOrderData.expiredHour > 0 || this.payOrderData.expiredMinutes > 0 || this.payOrderData.expiredSeconds > 0) {
          this.count = this.payOrderData.expiredSeconds
          this.timer2 = setInterval(async () => {
            if (this.count > 0) {
              this.count -= 1
            } else {
              if (this.payOrderData.expiredMinutes > 0) {
                this.payOrderData.expiredMinutes =
                  this.payOrderData.expiredMinutes - 1
                this.count = 59
              } else {
                if (this.payOrderData.expiredHour > 0) {
                  this.payOrderData.expiredHour =
                    this.payOrderData.expiredHour - 1
                  this.payOrderData.expiredMinutes = 59
                } else {
                  await closeOrder({ id: this.$route.params.orderNo })
                  // this.$router.push(
                  //   "/payResult?type=fail" +
                  //   "&orderId=" +
                  //   this.$route.params.orderNo +
                  //   "&message=訂單失效"
                  // )
                  clearInterval(this.timer2)
                }
              }
            }
          }, 1000)
        }
      }
    },
    spliceAddress (str) {
      const regex = new RegExp(`(?:${"●"})(.*)`)
      const match = (str || "").match(regex)
      return match && match[1] ? match[1] + "," : ""
    },
    handleAddress () {
      this.$router.push(`/user/address?type=1&id=${this.defaultAddresses.id}`)
      this.$store.dispatch("user/getEditAddress", this.defaultAddresses.id)
    },
    copyInformation (item) {
      let textToCopy = ""
      const paragraphs = document.querySelectorAll(".richText p")

      // 遍历每个 p 元素
      for (let i = 0; i < paragraphs.length; i++) {
        const paragraph = paragraphs[i]
        // 获取 p 元素内的文本内容
        const textContent = paragraph.textContent.trim()
        // 将文本内容添加到要复制的字符串中
        textToCopy += textContent + "\n" // 添加换行符
      }
      var tempInput = document.createElement("textarea")
      // 设置输入框的值为要复制的文本
      tempInput.value = textToCopy
      // 将输入框添加到页面中
      document.body.appendChild(tempInput)
      // 选中输入框中的文本
      tempInput.select()
      // 执行复制命令
      document.execCommand("copy")
      // 移除临时输入框
      document.body.removeChild(tempInput)
      this.$toast(this.$t("common"))
    },
    getFreightTotal () {
      this.payOrderData.shardingPayOrder?.forEach(item => {
        item.goods.forEach(i => {
          this.goodsTotal += i.price * i.amount
          this.length += i.amount
        })
      })
    },
    getViewallGoods (type) {
      if (type === "1") {
        this.orderIndex = undefined
        this.unfold = false
      } else {
        this.orderIndex = 3
        this.unfold = true
      }
    },
    handleAreaCode (item) {
      this.selectPhone = item.value
    },
    async getOrderDetail () {
      const res = await getProductOrderDetail()
      if (res.resultID === 1200) {
        this.orderData = res.data
        this.columns.push({
          label: this.orderData.paymentSet.onlineName,
          value: "1",
          text: ""
        })
        this.columns.push({
          label: this.orderData.paymentSet.pubTransferName,
          value: "2",
          tip: this.orderData.paymentSet.pubTransferCopywriting,
          text: this.orderData.paymentSet.pubTransferAccount
        })
        this.columns.push({
          label: this.orderData.paymentSet.chequeName,
          value: "3",
          tip: this.orderData.paymentSet.chequeCopywriting,
          text: this.orderData.paymentSet.chequeAddress
        })
      }
      console.log(this.orderData)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-content {
  padding-bottom: 80px;
}

.adderss {
  color: #1a1a1a;

  padding: 26px;
  background-color: #fff;
  border-radius: 0px 0px 16px 16px;
  margin-bottom: 12px;
  margin-left: 2px;
  font-size: 18px;

  .gotopay {
    width: 100%;
    padding: 10px;
    text-align: center;
    background: #90d12e;
    border-radius: 8px 8px 8px 8px;
    font-size: 15px;
    color: #ffffff;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .Cancelpay {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    text-align: center;
    background: #eeeeee;
    border-radius: 8px 8px 8px 8px;
  }

  .pay-time {
    font-size: 16px;
    color: #666666;
    // display: flex;
    align-items: center;
    justify-content: center;

    .time {
      margin-right: 4px;
      color: #f82207;
      text-align: center;
    }
  }

  .order-status {
    display: flex;
    align-items: center;
    justify-content: center;

    &.isCenter {
      justify-content: space-between;
    }

    .pay-name {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      img {
        margin-right: 2px;
        width: 24px;
      }
    }

    .checkStatus {
      background: #FCE7E5;
      padding: 2px 4px;
      box-sizing: border-box;
      border-radius: 4px 4px 4px 4px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #E84935;
    }
  }


  .address-detail {
    width: 100%;
    padding: 12px;
    background: #f8f8f8;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .address-street {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      color: #1a1a1a;
    }

    .address-city {
      margin-top: 2px;
      font-weight: 500;
      font-size: 13px;
      color: #666666;
    }

    .arrow {
      height: 16px;
      width: 16px;
      margin-left: 11px;
    }

    .top {
      display: flex;
      align-items: center;

      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      margin-bottom: 2px;

      .address-name {
        margin-right: 12px;
      }
    }
  }
}

.pay-type {
  padding: 16px;
  background-color: #fff;
  border-radius: 16px;
  margin-bottom: 12px;

  .pay-title {
    font-weight: 500;
    font-size: 16px;
    color: #1a1a1a;
    line-height: 24px;
  }

  .phone-list {
    .consignee-list {
      display: flex;
      align-items: center;

      .consignee {
        margin-right: 12px;
        margin-bottom: 2px;
      }
    }

    .consignee-address {
      font-size: 13px;
      color: #666666;
    }
  }
}

.Stash {
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  padding-top: 0;

  .Stash-title {
    font-weight: 500;
    font-size: 16px;
    color: #1a1a1a;
    padding-top: 16px;
  }

  .goods-item {
    margin-top: 16px;
    display: flex;

    .imgbor {
      border-radius: 12px;
      border: 1px solid #f8f8f8;
      overflow: hidden;
    }

    img {
      width: 120px;
      height: 120px;
    }

    .goods-info {
      margin-left: 16px;
      position: relative;

      .goods-name {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        width: 207px;
        font-size: 13px;
        color: #1a1a1a;
      }

      .goods-unit {
        font-size: 12px;
        color: #999999;
        margin-top: 4px;
      }

      .goods-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: 100%;

        .price {
          font-weight: 800;
          font-size: 16px;
          color: #f82207;
        }
      }
    }
  }

  .viewall {
    font-weight: 400;
    font-size: 15px;
    color: #3491fa;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    img {
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }

  .Distribution-mode {
    border-bottom: 1px solid #f8f8f8;
    padding-bottom: 16px;

    .Distribution-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 16px;
    }

    .label {
      font-size: 15px;
      color: #666666;
    }

    .freight {
      font-weight: 500;
      font-size: 15px;
      color: #f82207;
    }
  }
}

.leaveMessage {
  background-color: #fff;
  padding: 0 16px;
  padding-bottom: 6px;

  .aggregate-list {
    border-bottom: 1px solid #f8f8f8;
  }

  .order-no {
    margin-top: 16px;
  }

  .leaveMessage-title {
    margin-bottom: 16px;
  }

  .leaveMessage-inp {
    padding-bottom: 16px;
    border-bottom: 1px solid #f8f8f8;

    input {
      width: 100%;
      background-color: #f8f8f8;
      border-radius: 6px;
      height: 40px;
      padding-left: 12px;
    }
  }

  .aggregate {
    margin: 0 0 16px 0;
    padding-top: 16px;
  }

  .aggregate-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .discountsprice {
      font-weight: 500;
      font-size: 15px;
      color: #f82207;
    }

    .text-color {
      color: #7BB227;
      text-align: right;
    }
  }
}

.order-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  .price {
    font-weight: 800;
    font-size: 20px;
    color: #f82207;
  }

  .Placeorder {
    padding: 10px 45px;
    background: #90d12e;
    border-radius: 8px 8px 8px 8px;
    color: #fff;
    width: 100%;
    text-align: center;
  }
}

.checkstand {
  padding: 20px 16px 0;
  text-align: center;
  position: relative;
  padding-bottom: 60px;

  .checkstand-title {
    font-weight: 500;
    font-size: 17px;
    color: rgba(0, 0, 0, 0.9);
  }

  .close {
    width: 24px;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .pay-list {
    max-height: 270px;
    overflow: scroll;

    .pay-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;

      .pay-price {
        color: #f82207;
      }

      .price-text {
        color: #1a1a1a;
        margin-right: 2px;
      }
    }
  }

  .select-pay {
    text-align: left;
    margin-bottom: 16px;
  }

  .checkstand-price {
    font-weight: 800;
    font-size: 20px;
    color: #f82207;
    padding: 20px;
  }

  .phone-list {
    .actvie {
      border: 2px solid #90d12e !important;
    }

    .phone-item {
      padding: 16px;
      position: relative;
      background: #f8f8f8;
      border-radius: 8px 8px 8px 8px;
      margin-bottom: 12px;
      border: 2px solid transparent;

      .phone-text {
        font-size: 14px;
        color: #1a1a1a;
        line-height: 16px;
        text-align: center;
      }

      .pay-icon {
        width: 24px;
        margin-right: 8px;
      }

      .phone-item-icon {
        display: flex;
        align-items: center;
      }

      .phone-check {
        position: absolute;
        width: 24px;
        bottom: -1px;
        right: -1px;
      }
    }
  }

  .pay-footer {
    position: fixed;
    left: 0;
    width: 100%;
    background-color: #fff;
    padding: 9px;
    bottom: 0;
    border: 1px solid #fff;

    .pay-btn {
      padding: 10px 0;
      text-align: center;
      background: #90d12e;
      border-radius: 8px 8px 8px 8px;
      color: #fff;
    }
  }
}

.register-dialog {
  padding: 24px;

  .register-text {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #1a1a1a;
    text-align: center;

    span {
      color: #3491fa;
    }
  }

  .pay-btn {

    .Cancel,
    .Confirm {
      flex: 1;
      text-align: center;
      margin-top: 10px;
    }

    .Cancel {
      background: #eeeeee;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }

    .Confirm {
      font-size: 16px;
      color: #ffffff;
      background-color: #90d12e;
      border-radius: 12px 12px 12px 12px;
      padding: 8px;
    }
  }

  .title {
    text-align: center;
    margin-bottom: 12px;
    font-weight: 500;

    div {
      text-align: center;
    }

    img {
      width: 24px;
      position: absolute;
      right: 16px;
      top: 16px;
    }
  }
}

.stripe-container {
  padding: 20px;
  padding-bottom: 30px;
}
</style>
